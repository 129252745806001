.joinArea {
  position: relative;
  background: #f6f9ff;
  height: 110px;
  padding-top: 50px;
  position: relative;
  z-index: 10;
  @media (max-width: 992px) {
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.joinFl {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.joinSp {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.joinRi {
  background: #ffffff;
  border-radius: 5px;
  width: clamp(300px, 620px, 700px);
  display: flex;
  /*! align-items: center; */
  justify-content: center;
  flex-direction: column;
  //   padding-left: 80px;
  //   padding-right: 49px;
  padding: 15px 20px 15px 20px;
  border: 1px solid black;

  @media (max-width: 992px) {
    background: transparent;
    border: none;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #0c0908;
    margin-bottom: 24px;
  }
  p {
    font-size: 18px;
    line-height: 120%;
    color: #0c0908;
  }

  p.ctaText {
    margin-bottom: 1rem;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
}
.joinLeft {
  background: #bbc9d4;
  border-radius: 5px 0px 0px 5px;
  height: 180px;
  display: flex;
  /*! align-items: center; */
  flex-direction: column;
  width: 45%;
  /*! align-items: center; */
  justify-content: center;
  padding-left: 75px;

  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 115%;
    color: #0c0908;
    margin-bottom: 20px;

    span {
      color: #004bbb;
    }
  }

  p {
    font-size: 20px;
    line-height: 120%;
    color: #0c0908;
    margin: 0;
  }
}

/* Hubspot form styles */

.joinArea {
  div:global(#hubspotNewsletterFormContainer) {
    min-height: 50px;
    div:global(#hubspotNewsletterForm) {
      font-family: var(--body-font);
      form:global(.hs-form) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 600px;
        margin: auto;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        div:global(.hs-email) {
          flex: 1 1 auto;
          min-width: clamp(240px, 380px, 100%);
          box-sizing: border-box;
          margin-right: 1rem;
          @media (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 1rem;
          }

          label:global() {
            display: none;
          }
          input:global(.hs-input) {
            background: hsla(0, 0%, 71%, 0.39);
            border-radius: 5px;
            width: 100%;
            height: 50px;
            border: 0;
            font-size: 14px;
            line-height: 150%;
            color: #000;
            padding: 0 20px;
          }
        }
        div:global(.hs-submit) {
          flex: 1 1 auto;

          input:global(.button) {
            border-radius: 5px;
            border: none;

            &:hover {
              cursor: pointer;
            }
          }
        }
        ul:global(.hs-error-msgs) {
          display: none;
        }
      }
      div:global(.submitted-message) {
        font-size: 20px;
        p {
          color: var(--light-text);
        }
      }
    }
  }
}
